import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next';

import InfoCard from './InfoCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down("md")]: {
      flexDirection: 'column'
    },
    '& > div:last-child': {
			marginRight: 0
    },
		paddingRight: '10%',
    paddingLeft: '10%',
  }
}));

export default function Offer() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root} id='offer'>
      <InfoCard
        title={t('Value-focused teams')}
        info={t('agileTeamForming')}
        imageLink={process.env.PUBLIC_URL + '/assets/agile-team-forming.jpg'}
      />
      <InfoCard
        title={t('Scale-up')}
        info={t('agileTransformation')}
        imageLink={process.env.PUBLIC_URL + '/assets/collaboration-with-client.jpg'}
      />
      <InfoCard
        title={t('Advisory services')}
        info={t('collaborationWithClient')}
        imageLink={process.env.PUBLIC_URL + '/assets/agile-transformation.jpg'}
      />
    </div>
  );
};
