import React, { Suspense } from 'react'

import './i18n';

import Spinner from 'react-spinkit';

import { makeStyles } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core';

import Header from './components/Header';
import Offer from './components/Offer';
import Information from './components/Information';
import Footer from './components/Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    background: 'white',
    padding: '5px 0 0 0',
  },
}));

const Loader = () => (
  <Spinner name="line-spin-fade-loader" />
);

export default function App() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Suspense fallback={<Loader className={classes.spin} />}>
        <CssBaseline />
        <Header />
        <Information />
        <Offer />
        <Footer />
      </Suspense>
    </div>
  );
};


