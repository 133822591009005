import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Image from 'material-ui-image';
import Hidden from '@material-ui/core/Hidden';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingRight: '20%',
		paddingLeft: '20%',
		fontSize: '28px',
		lineHeight: '36px',
		fontFamily: 'Open Sans',
		color: '#404B4D'
	},
	paperTitle: {
		fontWeight: 'bold',
		margin: 0,
		textDecoration: 'underline',
		padding: theme.spacing(1),
	},
	paper: {
		alignSelf: 'center',
		padding: theme.spacing(1),
		margin: 0,
	},
	box: {
		alignSelf: 'center'
	}
}));

export default function Information() {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.root} id='information'>
			<Hidden xsDown>
				<Grid container justify={'center'} spacing={2}>
					<Grid item xs={12} sm={6}>
						<Image
							src={process.env.PUBLIC_URL + '/assets/extadio-company.jpg'}
							aspectRatio={(16 / 10)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} className={classes.box}>
						<p className={classes.paperTitle}>{t('We are Extadio')}</p>
						<p className={classes.paper}>{t('aboutExtadio')}</p>
					</Grid>
					<Grid item xs={12} sm={6} className={classes.box}>
					<p className={classes.paperTitle}>{t('Our values')}</p>
						<p className={classes.paper}>{t('weAreSure')}</p>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Image
							src={process.env.PUBLIC_URL + '/assets/productive-environment.jpg'}
							aspectRatio={(16 / 10)}
						/>
					</Grid>
				</Grid>

			</Hidden>
		</div>
	);
}
