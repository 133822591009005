import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
	root: {
        background: 'rgb(64, 75, 77, 0.75)',
        padding: '0 10%',
    },
    cover: {
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/footer.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'space-around',
        padding: '10vh 0 5vh'
    },
    contact: {
        color: 'white',
		fontSize: '28px',
        lineHeight: '36px',
        fontFamily: 'Open Sans'
    },
    form: {
        display: 'flex',
        width: '50%',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    half: {
        flexBasis: '48%',
        padding: '8px',
        margin: '0 0 18px',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        outline: 'none'
    },
    full: {
        flexBasis: '100%',
        padding: '8px',
        margin: '0 0 18px',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        outline: 'none'
    },
    big: {
        flexBasis: '100%',
        padding: '8px',
        height: '25vh',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        outline: 'none',
        resize: 'none'
    },
    submit: {
        alignSelf: 'center',
        margin: '10px 41%'
    }
}));

export default function Footer() {
    const classes = useStyles();
    
    const { t } = useTranslation();

	return (
        <div className={classes.root}>
            <div className={classes.cover}>
                <div className={classes.contact}>{t('Contact us')}</div>
                <form className={classes.form}>
                    <input type="text" defaultValue={t('First Name')} className={classes.half}/>
                    <input type="text" defaultValue={t('Second Name')} className={classes.half}/>
                    <input type="text" defaultValue={t('Email')} className={classes.full}/>
                    <input type="text" defaultValue={t('Subject')} className={classes.full}/>
                    <textarea defaultValue={t('Message')} className={classes.big}/>
                    <Button variant="contained" type="submit" className={classes.submit}>{t('Submit')}</Button>
                </form>
            </div>
        </div>
	);
}