import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		width: 0,
		margin: '20px 40px 20px 0',
		fontSize: '28px',
		lineHeight: '36px',
		fontFamily: 'Open Sans',
		flexGrow: 1
	},
	media: {
		minHeight: 440,
	},
	title: {
		textDecoration: 'underline',
		fontWeight: 'bold',
		marginBottom: 16
	},
	desc: {
		color: '#404B4D',
		fontSize: '28px',
		lineHeight: '36px',
		fontFamily: 'Open Sans',
	}
}));

export default function InfoCard({ title, info, imageLink }) {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardMedia
				component="img"
				height="340"
				image={imageLink}
			/>
			<CardContent>
				<div className={classes.title}>{title}</div>
				<Typography
					variant="body2"
					color="textSecondary"
					component="p"
					className={classes.desc}
				>
					{info}
				</Typography>
			</CardContent>
		</Card>
	);
}