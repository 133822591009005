import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
	root: {
		height: '100vh',
		backgroundImage: `url(${process.env.PUBLIC_URL}/assets/header.jpg)`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		marginBottom: '8px'
	},
	appbar: {
		background: 'white',
	},
	icon: {
		color: '#404B4D',
		fontSize: '2rem'
	},
	appbarIcon: {
		flexGrow: '1',
		fontFamily: 'Open Sans',
	},
	appbarWrapper: {
		width: '80%',
		margin: '0 auto',
	},
	colorIconText: {
		color: '#404B4D'
	},
	container: {
		textAlign: 'center',
		background: 'rgb(64, 75, 77, 0.5)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		width: '50%'
	},
	description: {
		color: 'white',
		textAlign: 'start',
		maxWidth: '50%',
		fontSize: '58px',
    	lineHeight: '64px',
		fontFamily: 'Open Sans',
	},
}));

export default function Header() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	const changeLanguage = lang => {
		i18n.changeLanguage(lang);
	};

	return (
		<div className={classes.root} id='header'>
			{/* ToDo: could be the separate component */}
				<AppBar className={classes.appbar} elevation={0}>
					<Toolbar className={classes.appbarWrapper}>
						<h1 className={classes.appbarIcon}>
							<span className={classes.colorIconText}>{t('extadio')}</span>
						</h1>
						<Button onClick={() => changeLanguage('en')}>{t('english')}</Button>
						<Button onClick={() => changeLanguage('ru')}>{t('russian')}</Button>
					</Toolbar>
				</AppBar>
			{/* ToDo: could be the separate component */}
			<div className={classes.container}>
				<div className={classes.description}>
					{t('description')}
				</div>
			</div>
		</div>
	);
};

